import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from './Header';
import Welcome from './Welcome';
import About from './About';
import Products from './Products';
import Footer from './Footer';
import Insights from './Insights';

function App() {
return (
<Router>
<div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
<Header />
<div style={{ flex: 1 }}>
<Routes>
<Route path="/" element={<Welcome />} />
<Route path="/about" element={<About />} />
<Route path="/products" element={<Products />} />
<Route path="/insights/:id" element={<Insights />} />
</Routes>
</div>
<Footer />
</div>
</Router>
);
}

export default App;