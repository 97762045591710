import React from 'react';
import { Stack, Grid, Container, Card, CardContent, Typography, Avatar, IconButton, Link } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Team() {
const teamMembers = [
{
name: 'Jens Leipziger',
role: 'CEO & Head of Engineering',
avatarUrl: '/team/jl.jpg',
email: 'mailto:jens.leipziger@comoutit.ch',
linkedIn: 'https://www.linkedin.com/in/jens-leipziger/'
},
{
name: 'Noah Mannhart',
role: 'IT-Projektleiter',
avatarUrl: '/team/nm.jpg',
email: 'mailto:noah.mannhart@comoutit.ch',
linkedIn: 'https://www.linkedin.com/in/noah-mannhart-2745a3228/'
},
{
name: 'Thomas Kramer',
role: 'Cloud Engineer',
avatarUrl: '/team/tk.jpg',
email: 'mailto:thomas.kramer@comoutit.ch',
linkedIn: 'https://www.linkedin.com/in/thomas-kramer-4914a043/'
},
{
name: 'Stefan Wehrli',
role: 'IT-Experte',
avatarUrl: '/team/sw.jpg',
email: 'mailto:stefan.wehrli@comoutit.ch',
linkedIn: 'https://www.linkedin.com/company/comoutit/'
},
{
name: 'Lukas Dreyer',
role: 'IT-Experte',
avatarUrl: '/team/ld.jpg',
email: 'mailto:lukas.dreyer@comoutit.ch',
linkedIn: 'https://www.linkedin.com/in/lukas-dreyer-22246b/'
},
{
name: 'Rolf Tröndle',
role: 'IT-Experte',
avatarUrl: '/team/rt.jpg',
email: 'mailto:rolf.troendle@comoutit.ch',
linkedIn: 'https://www.linkedin.com/company/comoutit/'
},
{
name: 'Diana Steger',
role: 'Manager Marketingkommunikation',
avatarUrl: '/team/ds.jpg',
email: 'mailto:diana.steger@comoutit.ch',
linkedIn: 'https://www.linkedin.com/in/dianasteger/'
},
{
name: 'Isuf Zheli',
role: 'Junior System Engineer',
avatarUrl: '/team/iz.jpg',
email: 'mailto:isuf.zheli@comoutit.ch',
linkedIn: 'https://www.linkedin.com/in/isufzheli/'
},
{
name: 'Sunguralp Cal',
role: 'Client Support',
avatarUrl: '/team/sc.jpg',
email: 'mailto:sunguralp.cal@comoutit.ch',
linkedIn: 'https://www.linkedin.com/in/sunguralp-cal-144905225/'
},
{
name: 'Yvonne Zimmermann',
role: 'Verwaltungsratspräsidentin',
avatarUrl: '/team/yz.jpg',
email: 'mailto:yvonne.zimmermann@comoutit.ch',
linkedIn: 'https://www.linkedin.com/in/zimmermannyvonne/'
},
];

return (
<Grid container spacing={3}>
{teamMembers.map((member) => (
<Grid item xs={12} md={6} lg={4} key={member.name}>

<Card sx={{ textAlign: 'center' }}>
<CardContent>

<Stack spacing={0}>
<Avatar alt={member.name} src={member.avatarUrl} sx={{ width: 56, height: 56, margin: 'auto' }} />
<Typography variant="h6">{member.name}</Typography>
<Typography variant="body2">{member.role}</Typography>

<Stack direction="row" spacing={0} sx={{ margin: 'auto' }}>
<Link href={member.email}>
<IconButton size="small" aria-label="email">
<EmailIcon />
</IconButton>
</Link>
<Link href={member.linkedIn} target="_blank" rel="noopener noreferrer">
<IconButton size="small" aria-label="LinkedIn">
<LinkedInIcon />
</IconButton>
</Link>
</Stack>

</Stack>
</CardContent>
</Card>

</Grid>
))}
</Grid>
);
}

export default Team;