import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
AppBar,
Toolbar,
IconButton,
Typography,
Drawer,
List,
ListItem,
ListItemText,
Box,
ListItemButton,
ListItemIcon,
Container,
Avatar,
Stack,
Button
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import logo from './logo.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function Header() {
const [open, setOpen] = useState(false);

const theme = useTheme();
const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

const toggleDrawer = (open) => (event) => {
if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
return;
}
setOpen(open);
};

const drawerList = (
<Box role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
<List>
{[
{ text: 'Home', icon: <HomeIcon />, link: '/' },
{ text: 'Über uns', icon: <PeopleIcon />, link: '/about' },
{ text: 'Produkte', icon: <RocketLaunchIcon />, link: '/products' },
].map((item) => (
<ListItem button component={Link} to={item.link} key={item.text}>
<ListItemIcon color="#ffffff">
{item.icon}
</ListItemIcon>
<ListItemText primary={item.text} />
</ListItem>
))}
</List>
</Box>
);

return (
<div>
<AppBar position="static" style={{ backgroundColor: '#2e2e38', boxShadow: 'none' }}>
<Container maxWidth="xl">

<Toolbar disableGutters>
<Box display="flex" flex={1} justifyContent="flex-start">
<IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
<MenuIcon />
</IconButton>
</Box>

<Box display="flex" flex={1} justifyContent="center">
<Link to="/" style={{ textDecoration: 'none', color: '#ffffff' }}>
<Stack direction="row" spacing={1} alignItems="center">
<Avatar src={logo} variant="rounded" sx={{ width: 24, height: 24 }}/>
<Typography variant={isSmallScreen ? "body1" : "h6"}>
Comout IT
</Typography>
</Stack>
</Link>
</Box>

<Box display="flex" flex={1} justifyContent="flex-end">
{isSmallScreen ? (
<IconButton color="inherit" component={Link} to="/about">
<PhoneIcon />
</IconButton>
) : (
<Button color="inherit" component={Link} to="/about">
Kontakt
</Button>
)}
</Box>

</Toolbar>

</Container>
</AppBar>

<Drawer
anchor="left"
open={open}
onClose={toggleDrawer(false)}
PaperProps={{ style: { minWidth: '250px', backgroundColor: '#2e2e38' } }}
>
{drawerList}
</Drawer>

</div>
);
}

export default Header;