import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Score from './Score.js';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';

function CustomTabPanel(props) {
const { children, value, index, ...other } = props;

return (
<div
role="tabpanel"
hidden={value !== index}
id={`simple-tabpanel-${index}`}
aria-labelledby={`simple-tab-${index}`}
{...other}
>
{value === index && (
<Box sx={{ p: 3 }}>
<Typography>{children}</Typography>
</Box>
)}
</div>
);
}

CustomTabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};

function a11yProps(index) {
return {
id: `simple-tab-${index}`,
'aria-controls': `simple-tabpanel-${index}`,
};
}

export default function BasicTabs() {
const [value, setValue] = React.useState(0);

const handleChange = (event, newValue) => {
setValue(newValue);
};

return (
<Box sx={{ width: '100%' }}>
<Typography variant="h5" mb={1}>
Das sagen unsere Kund:innen
</Typography>
<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
<Tabs
value={value}
onChange={handleChange}
variant="scrollable"
centered
scrollButtons
allowScrollButtonsMobile
aria-label="scrollable force tabs example"
centered
>
<Tab label="III" />
<Tab label="II" />
<Tab label="I" />
</Tabs>
</Box>

<CustomTabPanel value={value} index={0}>
<Stack direction="row" spacing={2}>
<Avatar src="./bss.png" />

<Typography variant="subtitle1">

<Stack direction="row" spacing={1} alignItems="top">
<Typography variant="h6">B-S-S Baumanagement AG</Typography>

<Link href="https://b-s-s.ch" target="_blank" rel="noopener noreferrer">
<IconButton size="small">
<LinkIcon />
</IconButton>
</Link>

</Stack>
Stefan Stocker, Geschäftsführer und Gründer<br />
<Score />
«Auf Anfrage hat uns Citrix Schweiz die Comout IT AG empfohlen. Seit der Installation unserer ersten Inhouse-Serveranlage vor mehr als 10 Jahren, wartet die Comout IT AG unsere IT-Infrastruktur vor Ort und remote. Beantwortet und betreut überzeugend unsere Fragen und anspruchsvollen Anliegen. Die Reaktionszeit liegt über der zu erwartenden Leistung, und ermöglicht es uns, auch kurzfristig anliegende IT-Projekte zu realisieren. Als Unternehmung in der Baubranche sind wir auf genau solch zuverlässige Partner angewiesen.»
</Typography>

</Stack>
</CustomTabPanel>


<CustomTabPanel value={value} index={1}>
<Stack direction="row" spacing={2}>
<Avatar src="./cardiance.png" />

<Typography variant="subtitle1">

<Stack direction="row" spacing={1} alignItems="top">
<Typography variant="h6">Cardiance Clinic AG</Typography>

<Link href="https://www.cardiance.com" target="_blank" rel="noopener noreferrer">
<IconButton size="small">
<LinkIcon />
</IconButton>
</Link>

</Stack>
Prof. Dr. A. Khattab, Medizinischer Leiter<br />
<Score />
«Die Cardiance Clinic ist die erste ambulante Herzklinik der Schweiz. Durch eine Empfehlung sind wir auf die Comout IT AG aufmerksam geworden. Seit über 8 Jahren betreut das Team nicht nur unsere IT, sondern unterstützt uns auch bei der Weiterentwicklung unserer Office-Infrastruktur. Dank der hohen Fachkompetenz in Elektronik und Bauplanung bietet Comout IT auch in nicht IT-Bereichen wertvolle Unterstützung.»
</Typography>

</Stack>
</CustomTabPanel>


<CustomTabPanel value={value} index={2}>
<Stack direction="row" spacing={2}>
<Avatar src="./stammertal.png" />

<Typography variant="subtitle1">

<Stack direction="row" spacing={1} alignItems="top">
<Typography variant="h6">Alters- und Pflegezentrum Stammertal</Typography>

<Link href="https://www.apz-stammertal.ch" target="_blank" rel="noopener noreferrer">
<IconButton size="small">
<LinkIcon />
</IconButton>
</Link>

</Stack>
Ralph Limoncelli, Leiter Finanz und Administration<br />
<Score />
«Nach dem Weggang unseres bisherigen IT-Dienstleisters haben wir uns nach eingehender Analyse für Comout IT AG als unseren IT-Hauptpartner entschieden. Trotz vieler involvierter Technologiepartner koordiniert Comout IT alles harmonisch zum Wohle des APZ. Der Ansatz von Comout IT ist stets zu spüren: Partnerschaftliche Zusammenarbeit zum Wohle des Kunden. Genau das erleben wir mit Comout IT AG.»
</Typography>

</Stack>
</CustomTabPanel>


</Box>

);
}