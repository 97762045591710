import React from 'react';
import { List, ListItem, Stack, Typography, Grid, Container } from '@mui/material';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import FindInPageRoundedIcon from '@mui/icons-material/FindInPageRounded';

function Contacts() {
return (
<Container>
<Grid container spacing={5}>
<Grid item xs={12} sm={6} md={4}>
<Stack direction="row" alignItems="top" spacing={1}>
<BusinessRoundedIcon />
<Typography variant="p">Comout IT AG<br/>Brunnenweid 49<br/>5643 Sins</Typography>
</Stack>
</Grid>
<Grid item xs={12} sm={6} md={4}>
<Stack direction="row" alignItems="top" spacing={1}>
<PhoneRoundedIcon />
<Typography variant="p">055 416 16 70<br/>Mo-Fr 8.00-17.00</Typography>
</Stack>
</Grid>
<Grid item xs={12} sm={6} md={4}>
<Stack direction="row" alignItems="top" spacing={1}>
<EmailRoundedIcon />
<Typography variant="p">info@comoutit.ch</Typography>
</Stack>
</Grid>
<Grid item xs={12} sm={6} md={4}>
<Stack direction="row" alignItems="top" spacing={1}>
<BusinessRoundedIcon />
<Typography variant="p">Comout IT AG<br/>Universitätstrasse 100<br/>8006 Zürich</Typography>
</Stack>
</Grid>

<Grid item xs={12} sm={6} md={4}>
<Stack direction="row" alignItems="top" spacing={1}>
<BusinessRoundedIcon />
<Typography variant="p">Comout IT AG<br/>Herr Jens Leipziger<br/>Bälstrasse 73<br/>8194 Hüntwangen</Typography>
</Stack>
</Grid>

<Grid item xs={12} sm={6} md={4}>
<Stack direction="row" alignItems="top" spacing={1}>
<FindInPageRoundedIcon />
<Typography variant="p">CHE-113.933.982<br/>Handelsregisteramt Kanton Aargau</Typography>
</Stack>
</Grid>

</Grid>
</Container>
);
}

export default Contacts;