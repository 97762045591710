import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, Grid, Hidden } from '@mui/material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Insights from './Insights';
import AllInsights from './AllInsights';
import useScrollToTop from './useScrollToTop';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import PeopleIcon from '@mui/icons-material/People';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

function Welcome() {
const theme = useTheme();
const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
useScrollToTop();

const getCurrentGreeting = () => {
const currentHour = new Date().getHours();
if (currentHour >= 0 && currentHour < 2) {
return 'Schlaflos wegen der IT?';
} else if (currentHour >= 2 && currentHour < 4) {
return 'Ärger mit der IT?';
} else if (currentHour >= 4 && currentHour < 6) {
return 'Technikfrust?';
} else if (currentHour >= 6 && currentHour < 8) {
return 'Systemausfall?';
} else if (currentHour >= 8 && currentHour < 10) {
return 'Softwareproblem?';
} else if (currentHour >= 10 && currentHour < 12) {
return 'Netzwerkärger?';
} else if (currentHour >= 12 && currentHour < 14) {
return 'Digitale Störung?';
} else if (currentHour >= 14 && currentHour < 16) {
return 'Hardwareausfall?';
} else if (currentHour >= 16 && currentHour < 18) {
return 'Sicherheitslücke?';
} else if (currentHour >= 18 && currentHour < 20) {
return 'Datenverlust?';
} else if (currentHour >= 20 && currentHour < 22) {
return 'Serverüberlastung?';
} else {
return 'Datenpanne?';
}
};

return (
<Container maxWidth="xl">
<Box my={5}>

<Typography variant={isLargeScreen ? "h1" : "h4"} sx={{ fontWeight: 500 }}>
{getCurrentGreeting()}
</Typography>

<Typography variant="h5">
IT-Outsourcing für Schweizer KMU – seit 2007
</Typography>

<Typography mt={2} variant={isLargeScreen ? "h5" : "p"}>Unser Unternehmen ist auf die Bereitstellung innovativer IT-Lösungen spezialisiert, die darauf zugeschnitten sind, Geschäftsprozesse effizient zu optimieren.

</Typography>

<Box mt={5}>
<Grid container spacing={2}>
<Grid item>
<Button variant="outlined" startIcon={<RocketLaunchIcon />} color="primary" size="large" component={Link} to="/products">
Produkte
</Button>
</Grid>
<Grid item>
<Button startIcon={<PeopleIcon />} component={Link} color="secondary" size="large" to="/about">
Über uns
</Button>
</Grid>
</Grid>
</Box>

<AllInsights />

<Box pt={5} display="flex" justifyContent="center">
<Stack
direction="row"
divider={<Divider orientation="vertical" flexItem />}
spacing={2}
>
<Link to="/about" style={{ color: '#fff' }} justifyContent="center">Über uns</Link>
<Link to="/products" style={{ color: '#fff' }} justifyContent="center">Produkte</Link>
</Stack>
</Box>

</Box>
</Container>
);
}

export default Welcome;