import * as React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';

const labels = {
1: 'Nutzlos',
2: 'Schecht',
3: 'Ok',
4: 'Gut',
5: 'Exzellent',
};

export default function TextRating() {
const value = 5;

return (
<Box
sx={{
width: 200,
display: 'flex',
alignItems: 'center',
}}
>
<Rating
name="text-feedback"
value={value}
readOnly
precision={1}
emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
size="small"
/>
<Box sx={{ ml: 2 }}>{labels[value]}</Box>
</Box>
);
}