import React from 'react';
import { useParams } from 'react-router-dom';
import articles from './articles';
import { Container, Box, Grid, Button } from '@mui/material';
import useScrollToTop from './useScrollToTop';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/People';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

// Define renderContent function outside of the components to make it globally accessible within this file
const renderContent = (content) => {
  return content.split('<br><br>').map((paragraph, index) => (
    <p key={index} dangerouslySetInnerHTML={{ __html: paragraph.replace(/<br \/>/g, ' ') }}></p>
  ));
};

const Insights = () => {
  let { id } = useParams();
  const article = articles.find(article => article.id.toString() === id);
  useScrollToTop();

  return (
    <div>
      <article>
        <img src={article.imageUrl} alt={article.title} style={{ width: '100%', height: 'auto' }} />
        <Container maxWidth="xl">
          <h1>{article.title}</h1>
          {renderContent(article.content)}

<Box mt={5}>
<Grid container spacing={2}>
<Grid item>
<Button variant="outlined" startIcon={<RocketLaunchIcon />} color="primary" size="large" component={Link} to="/products">
Produkte
</Button>
</Grid>
<Grid item>
<Button startIcon={<PeopleIcon />} component={Link} color="secondary" size="large" to="/about">
Über uns
</Button>
</Grid>
</Grid>
</Box>

        </Container>
      </article>

      <Box pt={5} display="flex" justifyContent="center">
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <Link to="/" style={{ color: '#fff' }}>Home</Link>
          <Link to="/about" style={{ color: '#fff' }}>Über uns</Link>
          <Link to="/products" style={{ color: '#fff' }}>Produkte</Link>
        </Stack>
      </Box>
    </div>
  );
};

export default Insights;