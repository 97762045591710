import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Products_1 from './Products_1';
import useScrollToTop from './useScrollToTop';
import AllInsights from './AllInsights';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

function Products() {
const theme = useTheme();
const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
useScrollToTop();

return (
<Container maxWidth="xl">
<Box my={5}>
<Typography variant={isLargeScreen ? "h1" : "h4"} sx={{ fontWeight: 500 }}>
Produkte 📦
</Typography>

<Typography variant="h5">Erfahren Sie mehr <Link to="/about" style={{ color: '#fff' }}><b>über uns</b></Link></Typography>

</Box>

<Box my={5}>
<Typography variant={isLargeScreen ? "h6" : "p"}>
Die Lösungen, die Sie jetzt einsetzen, legen den Grundstein für Ihren künftigen Erfolg. Erkunden Sie unsere Dienstleistungen und finden Sie heraus, wie Comout IT Ihre IT-Strategie entscheidend verbessern kann.
</Typography>
</Box>

<Box my={5}>
<Products_1 />
</Box>

<AllInsights />

<Box pt={5} display="flex" justifyContent="center">
<Stack
direction="row"
divider={<Divider orientation="vertical" flexItem />}
spacing={2}
>
<Link to="/" style={{ color: '#fff' }} justifyContent="center">Home</Link>
<Link to="/about" style={{ color: '#fff' }} justifyContent="center">Über uns</Link>
</Stack>
</Box>

</Container>
);
}

export default Products;