import React from 'react';
import { Container, Typography, Box, Button, List, ListItem, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import FindInPageRoundedIcon from '@mui/icons-material/FindInPageRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import Contacts from './Contacts.js';
import Team from './Team.js';
import Rating from './Rating.js';
import useScrollToTop from './useScrollToTop';
import AllInsights from './AllInsights';
import Divider from '@mui/material/Divider';

function About() {
const theme = useTheme();
const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
useScrollToTop();

return (
<Container maxWidth="xl">
<Box my={5}>
<Typography variant={isLargeScreen ? "h1" : "h4"} sx={{ fontWeight: 500 }}>
Comout IT AG 🇨🇭
</Typography>

<Typography variant="h5">Entdecken Sie unsere <Link to="/products" style={{ color: '#fff' }}><b>Produkte</b></Link></Typography>

</Box>

<Box my={5}>
<Typography variant={isLargeScreen ? "h6" : "p"}>
Unser Team unterstützt Kunden in allen Bereichen der IT, von der Strategieformulierung über die Auswahl von Technologien und Anbietern bis hin zu IT-Vertragsverhandlungen, der Entwicklung neuer IT-Strukturen und der Implementierung technologischer Lösungen.
</Typography>
</Box>

<Box my={5}>
<Contacts />
</Box>

<Box my={5} py={5} align="center">
<Typography variant="h5"><FavoriteBorderOutlinedIcon sx={{ fontSize: 60 }} /></Typography>
<Typography variant="h5" sx={{ fontWeight: 500 }}>Werden Sie Teil unserer Online-Familie</Typography>
<Typography>
Finden Sie uns auf <Link to="https://www.linkedin.com/company/comoutit/" target="_blank" style={{ color: '#fff' }}><b>LinkedIn</b></Link>
</Typography>
</Box>

<Box my={5}>
<Team />
</Box>

<Box my={5}>
<Typography variant={isLargeScreen ? "h6" : "p"}>
Im Jahr 2007 gründeten Yvonne Zimmermann und Jens Leipziger unser Unternehmen mit einem Team aus erfahrenen Wirtschafts- und IT-Experten. Seitdem sind wir stark gewachsen und konzentrieren uns vor allem auf neue Ideen und gute Arbeit. Unser Ansatz basiert auf drei Grundwerten: Integrität, Innovation und Kundenorientierung. Diese Werte helfen uns, IT-Dienstleistungen anzubieten und dauerhafte Beziehungen aufzubauen, die auf Vertrauen und einer Verpflichtung zur Qualität basieren.
</Typography>
</Box>

<Box my={5}>
<Rating />
</Box>

<Box my={5} sx={{ maxWidth: 'md', textAlign: 'center', margin: 'auto' }}>
<Typography variant="h5"><EnergySavingsLeafOutlinedIcon sx={{ fontSize: 60 }} /></Typography>
<Typography variant="h5" sx={{ fontWeight: 500 }}>Eco-friendly Computing</Typography>
<Typography>
Unser Planet und unsere Technologie liegen uns am Herzen. Wir setzen uns aktiv für energieeffiziente Betriebsabläufe ein und verpflichten uns, unseren eigenen ökologischen Fussabdruck zu reduzieren.
</Typography>
</Box>

<AllInsights />

<Box pt={5} display="flex" justifyContent="center">
<Stack
direction="row"
divider={<Divider orientation="vertical" flexItem />}
spacing={2}
>
<Link to="/" style={{ color: '#fff' }} justifyContent="center">Home</Link>
<Link to="/products" style={{ color: '#fff' }} justifyContent="center">Produkte</Link>
</Stack>
</Box>

</Container>
);
}

export default About;