import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { amber } from '@mui/material/colors';

import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import Stack from '@mui/material/Stack';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';


export function Insights3() {
  return (
    <Card>
      <CardActionArea component={Link} to="/insights/3">
        <CardMedia
          component="img"
          height="140"
          image="img3.jpeg"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          IT clever einkaufen
          </Typography>
          <Typography variant="body2" color="text.secondary">
In der dynamischen Tech-Welt ist die richtige IT-Strategie für Unternehmen entscheidend, um Effizienz, Innovation und Profit zu steigern
</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export function Insights2() {
  return (
    <Card>
      <CardActionArea component={Link} to="/insights/2">
        <CardMedia
          component="img"
          height="140"
          image="img2.jpeg"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          10 Gründe für IT-Outsourcing
          </Typography>
          <Typography variant="body2" color="text.secondary">
IT-Outsourcing könnte für Sie eine strategische Lösung sein, um Ihre Effizienz zu steigern, Ihre Abläufe zu straffen und einen digitalen Vorsprung zu gewinnen
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export function Insights1() {
  return (
    <Card>
      <CardActionArea component={Link} to="/insights/1">
        <CardMedia
          component="img"
          height="140"
          image="img1.jpeg"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          IT-Outsourcing einfach erklärt
          </Typography>
          <Typography variant="body2" color="text.secondary">
Entdecken Sie die Schlüsselbegriffe des Outsourcings als fundierten Einstieg, um dessen Vorteile und strategische Bedeutung in der modernen Wirtschaft besser zu verstehen
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}




export default function FullWidthGrid() {
  return (
    <Box pt={5} my={5}>

<Stack direction="row" alignItems="center" mb={3} spacing={1}>
<LightbulbOutlinedIcon fontSize="large" sx={{ color: amber[500] }} />
<Typography variant="h5">Einblicke</Typography>
</Stack>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <Insights3 />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Insights2 />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Insights1 />
        </Grid>
      </Grid>
    </Box>
  );
}