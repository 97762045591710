import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { List, ListItem, Stack, Chip } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import EngineeringRoundedIcon from '@mui/icons-material/EngineeringRounded';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import HandshakeIcon from '@mui/icons-material/Handshake';
import CloudIcon from '@mui/icons-material/Cloud';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import LockIcon from '@mui/icons-material/Lock';

const Accordion = styled((props) => (
<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
border: `1px solid ${theme.palette.divider}`,
'&:not(:last-child)': {
borderBottom: 0,
},
'&:before': {
display: 'none',
},
}));

const AccordionSummary = styled((props) => (
<MuiAccordionSummary
expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
{...props}
/>
))(({ theme }) => ({
backgroundColor:
theme.palette.mode === 'dark'
? 'rgba(255, 255, 255, .05)'
: 'rgba(0, 0, 0, .03)',
flexDirection: 'row-reverse',
'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
transform: 'rotate(90deg)',
},
'& .MuiAccordionSummary-content': {
marginLeft: theme.spacing(1),
},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
padding: theme.spacing(2),
borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
const [expanded, setExpanded] = React.useState('panel1');

const handleChange = (panel) => (event, newExpanded) => {
setExpanded(newExpanded ? panel : false);
};

return (
<div>

<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
<Typography>Digitalisierung</Typography>
</AccordionSummary>
<AccordionDetails>
<List>
<ListItem>
<Stack direction="row" alignItems="center" spacing={1} alignItems="flex-start">
<RocketLaunchIcon fontSize="large" />
<Typography variant="body1">
Für viele ist die Digitalisierung ein kostspieliges, riskantes Unterfangen mit einer steilen Lernkurve. Die Marktlösungen sind häufig komplex und nicht auf kleinere Unternehmen zugeschnitten. Für KMU ist es entscheidend, die Digitalisierung in ihrem eigenen Tempo und mit einer klaren Vision vor Augen zu übernehmen. Um die Kunden langfristig an sich zu binden, ist es von entscheidender Bedeutung, auf ihre individuellen Bedürfnisse schnell und effektiv einzugehen. Ist Ihr bestehendes Geschäftsmodell in der Lage, die wachsenden Erwartungen Ihrer Kunden zu erfüllen?
</Typography>
</Stack>
</ListItem>
</List>
</AccordionDetails>
</Accordion>

<Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
<AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
<Typography>IT-Outsourcing</Typography>
</AccordionSummary>
<AccordionDetails>
<List>
<ListItem>
<Stack direction="row" alignItems="center" spacing={1} alignItems="flex-start">
<EngineeringRoundedIcon fontSize="large" />
<Typography variant="body1">
Langsame und stetige Taktiken reichen in einem wettbewerbsintensiven Geschäftsumfeld nicht aus. Um sich eine führende Position zu sichern und den Markteinfluss zu erhöhen, ist es entscheidend, der Konkurrenz voraus zu sein und nicht nur mit ihr gleichzuziehen. Wir helfen Unternehmen, die Vorteile von IT-Outsourcing-Lösungen zu entdecken, zu verstehen und zu nutzen, indem wir Strategien entwickeln, die Unternehmen dabei helfen, die Fähigkeiten zu erwerben, die sie benötigen, um in der heutigen schnelllebigen, innovationsgetriebenen Umgebung effektiv zu konkurrieren.
</Typography>
</Stack>
</ListItem>
</List>
</AccordionDetails>
</Accordion>

<Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
<AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
<Typography>Management</Typography>
</AccordionSummary>
<AccordionDetails>
<List>
<ListItem>
<Stack direction="row" alignItems="center" spacing={1} alignItems="flex-start">
<HandshakeIcon fontSize="large" />
<Typography variant="body1">
Diese Dienstleistungen bieten Zugang zu erfahrenen IT-Experten, die Projekte leiten, Strategien empfehlen und Mitarbeiter schulen können, ohne zusätzliche Kosten wie Miete, Krankengeld, Urlaubsgeld, Ausbildungskosten und mögliche Abfindungen. Diese Dienstleistungen sind sowohl für grosse als auch für kleine Unternehmen geeignet und kombinieren Fachwissen mit modernster Technologie für ein effizientes IT-Projektmanagement mit einer Arbeitsbelastung von 2 Tagen im Monat bis zu 5 Tagen in der Woche.
</Typography>
</Stack>
</ListItem>
</List>
</AccordionDetails>
</Accordion>

<Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
<AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
<Typography>Schulungen</Typography>
</AccordionSummary>
<AccordionDetails>
<List>
<ListItem>
<Stack direction="row" alignItems="center" spacing={1} alignItems="flex-start">
<LockIcon fontSize="large" />
<Typography variant="body1">
Trotz erstklassiger Mitarbeiter und Technologien stellen die Mitarbeiter oft das grösste IT-Sicherheitsrisiko dar. Um dieses Risiko zu mindern, ist eine umfassende Schulung zur Cybersicherheit im gesamten Unternehmen unerlässlich. Durch diese Schulungen werden entscheidende Fähigkeiten zur Bewältigung von Sicherheitsherausforderungen vermittelt. Unsere Kurse verbessern die Fähigkeiten der Mitarbeiter bei der Erkennung von und Reaktion auf Cyber-Bedrohungen erheblich.
</Typography>
</Stack>
</ListItem>
</List>
</AccordionDetails>
</Accordion>

<Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
<AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
<Typography>Assistance</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography variant="h5">
Unsere Experten sind nur einen Anruf entfernt
</Typography>
<List>
<ListItem>
<Stack direction="row" alignItems="center" spacing={1} alignItems="flex-start">
<SupportAgentIcon fontSize="large" />
<Typography variant="body1">Hotline 044 512 14 20 <Chip label="Normaltarif" size="small" /></Typography>
</Stack>
</ListItem>
</List>
</AccordionDetails>
</Accordion>

    </div>
  );
}