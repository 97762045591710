import React from 'react';
import { Container, Box, Typography, IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Privacy from './Privacy';
import { Link } from 'react-router-dom';

function Footer() {
return (
<Box component="footer" color="white" py={4} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
<Container maxWidth="xl">
<Box display="flex" flexDirection="column" alignItems="center">
<a href="https://www.linkedin.com/company/comoutit/" target="_blank" rel="noopener noreferrer">
<IconButton aria-label="LinkedIn">
<LinkedInIcon />
</IconButton>
</a>
<Typography variant="body2" align="center">
© {new Date().getFullYear()} Comout IT AG - <a href="/agb.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>AGB</a> - <Privacy />
</Typography>
</Box>
</Container>
</Box>
);
}

export default Footer;