import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { yellow } from '@mui/material/colors';

const darkTheme = createTheme({
palette: {
mode: 'dark',
primary: {
main: '#fff',
contrastText: '#ffffff',
},
secondary: {
main: '#fff',
contrastText: '#ffffff',
},
background: {
default: '#2e2e38',
paper: '#2e2e38',
},
text: {
primary: '#ffffff',
},
},
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<ThemeProvider theme={darkTheme}>
<CssBaseline />
<React.StrictMode>
<App />
</React.StrictMode>
</ThemeProvider>
);